import styles from './index.module.scss'
import {useSelector} from "react-redux";
import {useEffect} from "react";
import {useDispatch} from "react-redux";
import BaltexSections from "../../../baltex/sections";
import {getBaltexSections} from "../../../../redux/action-creaters/baltex";

export default function TopSections() {
    const {sections} = useSelector(state => state.baltex)
    const dispatch = useDispatch();

    useEffect(() => {
        if (sections.length === 0) {
            dispatch(getBaltexSections())
        }
    }, [])

    return (
        <div className={styles.TopSections}>
            <div className='container'>
                <BaltexSections/>
            </div>
        </div>
    )
}

function getSectionsTree(sections, parent = null) {
    return Object.values(sections).filter(section => section['IBLOCK_SECTION_ID'] == parent).map(section => {
        return {...section, CHILD: section['HAS_CHILDS'] === 'Y' ? getSectionsTree(sections, section['ID']) : []};
    }).sort((a, b) => a.SORT - b.SORT);
}