import Button from "/components/common/button"
import {useRouter} from "next/router";
import Icon from "/components/common/icon";
import {useMemo, useState} from "react";
import {getBacket, removeFromCart} from "/redux/action-creaters/cart";
import {useDispatch, useSelector} from "react-redux";
import styles from "./index.module.scss";
import Link from 'next/link'
import {showPopup} from "/redux/action-creaters/popup";
import {popupTypes} from "/redux/reducers/popupReducer";
import {declOfNum} from "../../../../utils";
import clsx from "clsx";
import { Api } from "../../../../api";

export default function Cart({basket}) {
    const router = useRouter();
    const dispatch = useDispatch();

    const [isShow, setShow] = useState(false)

    const [deletingId, setDeletingId] = useState(0)

    const cnt = useMemo(() => {
        return basket ? basket.QTY : 0;
    }, [basket])

    const sum = useMemo(() => {
        return basket?.PRICE
    }, [basket])

    const sumWithDiscount = useMemo(() => {
        return basket?.DISCOUNT_PRICE
    }, [basket])

    const total = useMemo(() => {
        return basket?.PRICE
    }, [basket])

    const {sections} = useSelector(state => state.sections)

  return (
    <>
        <div
            className={styles.Cart}
            onMouseEnter={() => setShow(true)}
            onMouseLeave={() => setShow(false)}
        >
            <div className={styles.CartIconWrapper} onClick={() => {
                router.push('/personal/cart/')
            }}>

                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M20.0449 10.8435C19.5638 12.4216 18.1078 13.5 16.4579 13.5H9.04213C7.39225 13.5 5.93618 12.4216 5.45509 10.8435L3.75 5.25H21.75L20.0449 10.8435Z"
                        stroke="#191919" strokeWidth="2" strokeMiterlimit="10"/>
                    <path d="M4.875 9L2.95604 2.9233C2.75898 2.29926 2.18009 1.875 1.52567 1.875H0.375" stroke="#191919"
                          strokeWidth="2" strokeMiterlimit="10"/>
                    <path
                        d="M14.9818 13.5H7.875C6.83947 13.5 6 14.3395 6 15.375C6 16.4105 6.83947 17.25 7.875 17.25H18.75"
                        stroke="#191919" strokeWidth="2" strokeMiterlimit="10"/>
                    <path
                        d="M8.25 22.5C9.07843 22.5 9.75 21.8284 9.75 21C9.75 20.1716 9.07843 19.5 8.25 19.5C7.42157 19.5 6.75 20.1716 6.75 21C6.75 21.8284 7.42157 22.5 8.25 22.5Z"
                        fill="#191919"/>
                    <path
                        d="M17.25 22.5C18.0784 22.5 18.75 21.8284 18.75 21C18.75 20.1716 18.0784 19.5 17.25 19.5C16.4216 19.5 15.75 20.1716 15.75 21C15.75 21.8284 16.4216 22.5 17.25 22.5Z"
                        fill="#191919"/>
                </svg>


                {cnt > 0 && (<span>{cnt}</span>)}
            </div>
            {cnt > 0 && isShow && (<div className={styles.PopupCart}>
                <div className={styles.Items}>
                    {basket.ITEMS.map(item => (
                        <div className={styles.Item} key={item.ID}>
                            <div className={styles.Photo}>
                                <Link href={item.URL ?? '/'}>
                                    <a className={styles.Name}>
                                        <img src={item.PICTURE}/>
                                    </a>
                                </Link>
                            </div>
                            <div className={styles.Name}>
                                <Link href={item.URL ?? '/'}><a className={styles.Name}>{item.NAME}</a></Link>
                                <div className={styles.Price}>
                                    {item.PRICE_TOTAL} руб ({item.QUANTITY} шт)
                                </div>
                            </div>

                            <div className={styles.Delete}  onClick={async () => {
                                if (deletingId) return
                                setDeletingId(item.ID)
                                await Api.basket.removeFromBasket(item.ID)
                                await dispatch(getBacket(true))
                                setDeletingId(0)

                                window.dataLayer = window.dataLayer || [];
                                dataLayer.push({
                                    "ecommerce": {
                                        "currencyCode": "RUB",
                                        "remove": {
                                            "products": [
                                                {
                                                    "id": item.ID,
                                                    "name": item.NAME,
                                                    "category": sections[item.SECTION_ID].NAME,
                                                    "quantity": item.QUANTITY,
                                                    "list": "Всплывающая корзина",
                                                }
                                            ]
                                        }
                                    }
                                });
                            }}>
                                {deletingId && deletingId === item.ID ? (
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 50 50">
                                        <path fill="#333" d="M25,5A20.14,20.14,0,0,1,45,22.88a2.51,2.51,0,0,0,2.49,2.26h0A2.52,2.52,0,0,0,50,22.33a25.14,25.14,0,0,0-50,0,2.52,2.52,0,0,0,2.5,2.81h0A2.51,2.51,0,0,0,5,22.88,20.14,20.14,0,0,1,25,5Z">
                                            <animateTransform attributeName="transform" type="rotate" from="0 25 25" to="360 25 25" dur="0.5s" repeatCount="indefinite"/>
                                        </path>
                                    </svg>
                                ) : (
                                    <Icon id='trash' fill={'#8d8d8d'} width={22} height={22}/>
                                )}
                            </div>
                        </div>
                    ))}
                </div>

                <div className={styles.Total}>
                    <div className={styles.TotalItem}>
                        <div>Стоимость { declOfNum(basket?.QTY, ['товара', 'товаров', 'товаров']) }</div>
                        <div>{ sum } руб</div>
                    </div>
                    {sumWithDiscount > 0 && (
                        <div className={styles.TotalItem}>
                            <div>Скидка</div>
                            <div>- {sumWithDiscount } руб</div>
                        </div>
                    )}
                    <div className={clsx(styles.TotalItem, styles.TotalItemFinal)}>
                        <div>Итого</div>
                        <div>{ total } руб</div>
                    </div>
                    <div className={styles.Bottom}>
                        <Button
                            type={'outline'}
                            onClick={() => {
                                dispatch(showPopup(popupTypes.buyOneClick))
                            }}
                            label={'Быстрый заказ'}
                        />
                        <Button
                            onClick={() => { router.push('/personal/cart/') }}
                            label={'Оформить заказ'}
                        />
                    </div>
                </div>
            </div>)}
        </div>
        <Button
            customStyles={styles.CartMobileButton}
            type={'none'}
            onClick={() => { router.push('/personal/cart/') }}
            before={
                <div className={styles.CartIconWrapper}>
                    <Icon id='cart' width={24} height={24} customStyles={styles.CartMobileIcon}/>
                    {cnt > 0 && (<span>{cnt}</span>)}
                </div>
            }
        />
    </>
  )
}

