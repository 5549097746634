import Button from "/components/common/button"
import {useDispatch} from "react-redux";
import {useRouter} from "next/router";
import {showPopup} from "/redux/action-creaters/popup";
import {popupTypes} from "/redux/reducers/popupReducer";
import {useMemo} from "react";
import styles from "./index.module.scss";
import React from "react";
import Icon from "/components/common/icon";

export default React.memo(function User({user}) {
    const router = useRouter()
    const dispatch = useDispatch();

    const isAuthorized = useMemo(() => {
        return Boolean(user?.ID)
    }, [user])


    return (
        <>
            <Button
                type={'none'}
                onClick={() => {
                    if (isAuthorized) {
                        router.push('/personal/')
                    } else {
                        dispatch(showPopup(popupTypes.auth))
                    }
                }}
                before={

                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clipPath="url(#clip0_73_6190)">
                            <mask id="mask0_73_6190" style={{'mask-type':'alpha'}} maskUnits="userSpaceOnUse" x="-1" y="3"
                                  width="25" height="22">
                                <path
                                    d="M17.92 12.2549L22.1896 4.27741C22.357 3.96458 22.683 3.76929 23.0379 3.76929C23.5692 3.76929 24 4.20005 24 4.73142V20.3847C24 22.9337 21.9336 25.0001 19.3846 25.0001H3.61538C1.06638 25.0001 -1 22.9337 -1 20.3847V5.35681C-1 4.48005 -0.28924 3.76929 0.587527 3.76929C1.17299 3.76929 1.71093 4.09152 1.98719 4.6077L6.08005 12.2549C6.88323 13.7556 8.44717 14.6924 10.1493 14.6924H13.8507C15.5528 14.6924 17.1168 13.7556 17.92 12.2549Z"
                                    fill="#D9D9D9"/>
                            </mask>
                            <g mask="url(#mask0_73_6190)">
                                <path
                                    d="M6.93874 13H17.0606C18.9409 13 20.5073 14.4413 20.6635 16.3151L21.0667 21.1538H2.93262L3.33585 16.3151C3.492 14.4413 5.05842 13 6.93874 13Z"
                                    stroke="#191919" strokeWidth="2"/>
                            </g>
                            <path
                                d="M11.9102 2.84619H12.0906C14.3247 2.84619 16.0241 4.85225 15.6568 7.05594L15.3133 9.11664C15.0434 10.7361 13.6422 11.9231 12.0004 11.9231C10.3586 11.9231 8.9574 10.7361 8.68748 9.11665L8.34403 7.05594C7.97675 4.85225 9.67614 2.84619 11.9102 2.84619Z"
                                stroke="#191919" strokeWidth="2"/>
                        </g>
                        <defs>
                            <clipPath id="clip0_73_6190">
                                <rect width="24" height="24" fill="white"/>
                            </clipPath>
                        </defs>
                    </svg>

                }
            />
        </>
    )
})