import styles from './index.module.scss'
import Link from 'next/link'
import {useMemo} from "react";

export default function Menu({cityName}) {

    const isShowServices = useMemo(() => {
        return ['Москва', 'Санкт-Петербург'].includes(cityName)
    })

  return (
    <ul className={styles.Links}>
        <li>
            <Link href="/services/">Услуги</Link>
        </li>
        <li>
            <Link href="/articles/">Статьи и новости</Link>
        </li>
        <li>
            <Link href="/contacts/">Контакты</Link>
        </li>
    </ul>
  )
}

