import styles from './index.module.scss'
import Link from 'next/link'
import {useMemo} from "react";

export default function Menu({cityName}) {

    const isShowServices = useMemo(() => {
        return ['Москва', 'Санкт-Петербург'].includes(cityName)
    })

  return (
      <ul className={styles.Links}>
          <li>
              <Link href="/info/">Для покупателей</Link>
          </li>
          <li>
              <Link href="/services/">Услуги</Link>
          </li>
          <li>
              <Link href="/reviews/">Отзывы</Link>
          </li>
          <li>
              <Link href="/about/">О компании</Link>
          </li>
          <li>
              <Link href="/contacts/">Контакты</Link>
          </li>
      </ul>
  )
}

