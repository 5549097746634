import {useDispatch, useSelector} from "react-redux";
import {useEffect} from "react";
import {fetchMainPopular} from "../../../redux/action-creaters/content";
import styles from "./index.module.scss";
import Link from 'next/link'

export default function BaltexSections() {
    const {sections} = useSelector(state => state.baltex)

    return (
        <div className={styles.Sections}>
            {sections.length > 0 && (
                <div className={styles.SectionsList}>
                    <Link key={sections[0].ID} prefetch={false} href={sections[0].PROPERTY_LINK_VALUE || '/catalog/farkopy/'}>
                        <a>
                            <span className={styles.Image} style={{backgroundImage: `url(${sections[0].PICTURE})`}}/>
                            <span className={styles.Text}>
                                <h3>{sections[0].NAME}</h3>
                                <div dangerouslySetInnerHTML={{__html:sections[0].PREVIEW_TEXT}}></div>
                            </span>
                        </a>
                    </Link>
                    <Link key={sections[1].ID} prefetch={false} href={sections[1].PROPERTY_LINK_VALUE || '/catalog/vstavki_pod_kvadrat/'}>
                        <a>
                            <span className={styles.Image} style={{backgroundImage: `url(${sections[1].PICTURE})`}}/>
                            <span className={styles.Text}>
                                <h3>{sections[1].NAME}</h3>
                                <div>{sections[1].PREVIEW_TEXT}</div>
                            </span>
                        </a>
                    </Link>
                    <Link key={sections[2].ID} prefetch={false} href={sections[2].PROPERTY_LINK_VALUE || '/catalog/farkopy/'}>
                        <a>
                            <span className={styles.Image} style={{backgroundImage: `url(${sections[2].PICTURE})`}}/>
                            <span className={styles.Text}>
                                <h3>{sections[2].NAME}</h3>
                                <div>{sections[2].PREVIEW_TEXT}</div>
                            </span>
                        </a>
                    </Link>
                    <Link key={sections[3].ID} prefetch={false} href={sections[3].PROPERTY_LINK_VALUE || '/'}>
                        <a>
                            <span className={styles.Image} style={{backgroundImage: `url(${sections[3].PICTURE})`}}/>
                            <span className={styles.Text}>
                                <h3>{sections[3].NAME}</h3>
                                <div>{sections[3].PREVIEW_TEXT}</div>
                            </span>
                        </a>
                    </Link>


                </div>
            )}
        </div>
    )
}